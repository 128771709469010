import axios from "axios";
import store from "@/store";
import { decrypt } from "./cryptojs";
import { useToast } from "vue-toastification";

const toast = useToast();
// HTTP超时时间
export const httpTimeout = 60000;

// 钉钉AK
export const DingAK = "ding1bc274eaad2f53ecffe93478753d9884";

// 七牛云文件域名
export const img9Url = "https://img9.sotecauto.com";

//正式环境
export const HttpUrl = "https://api.smart.sotecauto.com/api/v1";
export const WebSocketMonitorUrl = "wss://wss.smart.sotecauto.com/monitor";
export const WebSocketMessageUrl = "wss://wss.smart.sotecauto.com/message";

// //测试环境(外网)
// export const HttpUrl = "http://smart.gogs.sotecauto.com:7508/api/v1";
// export const WebSocketUrl = "ws://smart.gogs.sotecauto.com:7513/chat";
// //测试环境(外网)
// export const HttpUrl = "http://test.sotec.sotecauto.com:7508/api/v1";
// export const WebSocketUrl = "ws://test.sotec.sotecauto.com:7513/chat";
// //测试环境(外网)
// export const HttpUrl = "http://test.sotecauto.com/api/v1";
// export const WebSocketUrl = "ws://test.sotecauto.com/chat";

// //测试环境(内网)
// export const HttpUrl = "http://192.168.1.3:7509/api/v1";
// export const WebSocketUrl = "ws://192.168.1.3:7503/chat";

// // 个人热点
// export const HttpUrl = "http://172.18.58.61:7509/api/v1";
// export const WebSocketUrl = "ws://172.18.58.61:7503/chat";

// //测试环境-公司
// export const HttpUrl = "http://172.18.44.202:7509/api/v1";
// export const WebSocketMonitorUrl = "ws://172.18.44.202:7503/monitor";
// export const WebSocketMessageUrl = "ws://172.18.44.202:7504/message";

// //测试环境-家里
// export const HttpUrl = "http://172.18.45.221:7509/api/v1";
// export const WebSocketUrl = "ws://172.18.45.221:7503/chat";

// axios实例
const requests = axios.create({
    baseURL: HttpUrl,
    timeout: httpTimeout,
    headers: {
        "Content-Type": "application/json",
    },
});

// request interceptor(请求拦截器)
requests.interceptors.request.use(
    (config) => {
        let token = store.getters["auth/token"];
        if (token && token !== "null") {
            token = decrypt(token);
            if (token.substr(0, 6) === "Bearer") {
                config.headers["authorization"] = token;
            } else {
                toast.error("请求错误！");
                return Promise.reject("请求错误");
            }
        }
        return config;
    },
    (error) => {
        // console.log(error);
        toast.error(error);
        return Promise.reject(error);
    },
);

// response interceptor（接收拦截器）
requests.interceptors.response.use(
    (response) => {
        if (!response.data) {
            toast.error("请求失败！");
            return Promise.reject("请求失败");
        }
        const res = response.data;
        if (!res.code || !res.msg) {
            toast.error("解析失败！");
            return Promise.reject("解析失败");
        }
        if (parseInt(res.code) === 500) {
            toast.error(res.msg);
            return Promise.reject(res.msg);
        }
        if (parseInt(res.code) === 2003) {
            toast.error(res.msg);
            store.dispatch("auth/logout");
            return Promise.reject(res.msg);
        }
        if (parseInt(res.code) === 999) {
            toast.error(res.msg);
            return Promise.reject(res.msg);
        }
        if (!res.success) {
            toast.error(res.msg);
            return Promise.reject(res.msg);
        }

        const token = response.headers["authorization"];
        if (token) store.dispatch("auth/setToken", token);
        return res;
    },
    (error: any) => {
        if (error.message.includes("timeout")) {
            toast.error("服务器连接超时！");
        } else {
            if (error.response) {
                const data = error.response.data;
                if (error.response.status === 404) {
                    toast.error("未知接口！");
                } else if (error.response.status === 403) {
                    toast.error(data.error || data.message || data.msg);
                } else if (error.response.status === 401) {
                    toast.error("暂无权限！");
                    const token = store.getters["auth/token"];
                    if (token) store.dispatch("auth/logout");
                }
            }
        }
        return Promise.reject(error);
    },
);

export default requests;
