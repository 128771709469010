import VueAlert from "./vue-alert.vue";
import { createApp } from "vue";

export default {
    install(Vue: any) {
        const showNextAlert = function (args: any) {
            const AlertConstructor = createApp(VueAlert);
            const instance: any = AlertConstructor.mount(document.createElement("div"));
            // 设置回调函数
            instance.callback = function (action: any) {
                if (action === "confirm") {
                    args.resolve(action);
                    return true;
                } else if (action === "cancel" || action === "close") {
                    args.reject(action);
                }
                return false;
            };
            // 处理参数
            for (const prop in args.options) {
                instance[prop] = args.options[prop];
            }
            // 插入Body
            //document.body.appendChild(instance.$el);
            document.getElementById("wrapper").appendChild(instance.$el);
            instance.$nextTick(() => {
                instance.openModel();
            });
        };

        const alertFun = function (options: any) {
            if (typeof options === "string" || options === "number") {
                options = {
                    message: options,
                };
            }
            return new Promise((resolve, reject) => {
                showNextAlert({
                    options,
                    resolve: resolve,
                    reject: reject,
                });
            });
        };

        Vue.config.globalProperties.$vueAlert = alertFun;
    },
};
