import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-svg-icon",
    props: {
        name: {
            type: String,
            required: true,
        },
        class: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: () => "1em",
        },
        height: {
            type: String,
            default: () => "1em",
        },
        size: {
            type: String,
            default: "",
        },
    },
})
export default class SvgIcon extends Vue {
    private class: string;
    private width: string;
    private height: string;
    private size: string;

    get svgClass() {
        if (this.class) {
            return "svg-icon " + this.class;
        } else {
            return "svg-icon";
        }
    }

    get w(): string {
        if (this.size === "mini") {
            return "0.8em";
        } else if (this.size === "sm") {
            return "1em";
        } else if (this.size === "md") {
            return "1.5em";
        } else if (this.size === "lg") {
            return "2em";
        } else if (this.size === "xl") {
            return "2.5em";
        } else {
            return this.width;
        }
    }

    get h(): string {
        if (this.size === "mini") {
            return "0.8em";
        } else if (this.size === "sm") {
            return "1em";
        } else if (this.size === "md") {
            return "1.5em";
        } else if (this.size === "lg") {
            return "2em";
        } else if (this.size === "xl") {
            return "2.5em";
        } else {
            return this.height;
        }
    }
}
