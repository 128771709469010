import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "main",
        redirect: "/dashboard",
        component: () => import(/* webpackChunkName: "layout" */ "@/modules/layout/layout.vue"),
        meta: {
            title: "首页",
            requiresAuth: true,
            keepAlive: true,
        },
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/dashboard/dashboard.vue"),
                meta: {
                    title: "信息概览",
                    requiresAuth: true,
                    keepAlive: true,
                    icon: "pie-chart-outline",
                },
            },
            {
                path: "profile",
                name: "profile",
                component: () => import(/* webpackChunkName: "profile" */ "@/pages/profile/profile.vue"),
                meta: {
                    title: "个人设置",
                    requiresAuth: true,
                    keepAlive: true,
                },
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/modules/login/login.vue"),
        meta: {
            title: "登入",
        },
    },
    {
        path: "/loginDingtalk",
        name: "loginDingtalk",
        component: () => import(/* webpackChunkName: "login-dingtalk" */ "@/modules/login-dingtalk/login-dingtalk.vue"),
        meta: {
            title: "钉钉登入",
        },
    },
    {
        path: "/loginModPass",
        name: "loginModPass",
        component: () => import(/* webpackChunkName: "login-mod-pass" */ "@/modules/login-mod-pass/login-mod-pass.vue"),
        meta: {
            title: "首次登入",
        },
    },
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "layout" */ "@/modules/layout/layout.vue"),
        redirect: "/error/403",
        meta: {
            title: "首页",
            requiresAuth: true,
        },
        children: [
            {
                path: "/error/403",
                name: "error-403",
                component: () => import(/* webpackChunkName: "error403" */ "@/pages/error/403/403.vue"),
                meta: {
                    title: "403",
                },
            },
            {
                path: "/error/404",
                name: "error-404",
                component: () => import(/* webpackChunkName: "error404" */ "@/pages/error/404/404.vue"),
                meta: {
                    title: "404",
                },
            },
        ],
    },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: '/error/404'
    // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
