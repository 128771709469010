import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-switch",
    props: {
        modelValue: Number,
        label: String,
        enableName: {
            type: String,
            default: "启用",
        },
        disabledName: {
            type: String,
            default: "禁用",
        },
    },
    emits: ["update:modelValue"],
})
export default class SwitchNumber extends Vue {
    private modelValue: number;

    get isEnable(): boolean {
        return this.modelValue === 1;
    }

    set isEnable(val: boolean) {
        this.$emit("update:modelValue", val ? 1 : 2);
    }

    public enable() {
        this.isEnable = true;
    }

    public disabled() {
        this.isEnable = false;
    }
}
