import requests from "@/utils/axios";

export default {
    modulesUrl: "/auth",
    // 登入认证
    login(query: any) {
        return requests.post(this.modulesUrl + "/login", query);
    },
    // 钉钉登入认证
    loginDingtalk(query: any) {
        return requests.post(this.modulesUrl + "/loginDingtalk", query);
    },
    // 首次登入修改密码
    loginModPass(query: any) {
        return requests.post(this.modulesUrl + "/loginModPass", query);
    },
    // 获取页面角色
    getPageRole(query: any) {
        return requests.post(this.modulesUrl + "/getPageRole", query);
    },
    // 获取页面菜单
    getPageMenu() {
        return requests.post(this.modulesUrl + "/getPageMenu");
    },
};
