import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-model-select-from-db",
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        submitFunction: {
            type: Function,
            required: true,
        },
        title: {
            type: String,
            default: "修改",
        },
        submitButtonName: {
            type: String,
            default: "提交",
        },
        size: {
            type: String,
            default: "md",
        },
        selectApi: {
            type: Object,
            default: null,
        },
    },
})
export default class ModelSelectFromDb extends Vue {
    private loading = false;
    private value: string = null;
    private curValue: string = null;
    private submitFunction: any = null;

    get modValue() {
        return (this.curValue = this.value);
    }

    set modValue(val: any) {
        this.curValue = val;
    }

    public openModal() {
        this.$nextTick(() => {
            (this.$refs.textareaModal as any).openModal();
        }).then();
    }

    public async modFunction(): Promise<any> {
        return await this.submitFunction(this.curValue);
    }
}
