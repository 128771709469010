import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { decrypt } from "@/utils/cryptojs";
import UserInfo from "@/entity/UserInfo";

const authModule: any = {
    namespaced: true,
    state: {
        token: localStorage.getItem("token"),
        userInfo: getUserInfo(),
        pageRole: {},
    },
    mutations,
    actions,
    getters,
};

function getUserInfo(): UserInfo {
    try {
        const userInfo: any = localStorage.getItem("userInfo");
        if (userInfo) {
            return JSON.parse(decrypt(userInfo));
        } else {
            return new UserInfo();
        }
    } catch (e: any) {
        return new UserInfo();
    }
}

export default authModule;
