import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-content-header",
})
export default class ContentHeader extends Vue {
    get title() {
        return this.$route.meta.title;
    }
    get icon() {
        return this.$route.meta.icon;
    }
}
