export default {
    setMenu: (state: any, payload: any): void => {
        state.menu = payload;
    },
    setAllRoutes: (state: any, payload: any): void => {
        state.allRoutes = payload;
    },
    setAsyncRoutes: (state: any, payload: any): void => {
        state.asyncRoutes = payload;
    },
    putTabsList: (state: any, payload: any): void => {
        // eslint-disable-next-line no-prototype-builtins
        //if (!state.tabsList.hasOwnProperty(payload.name)) {
        state.tabsList[payload.name] = {
            path: payload.path,
            query: payload.query,
            name: payload.name,
            title: payload.meta.title,
            icon: payload.meta.icon,
        };
        //}
        Object.keys(state.tabsList).forEach((name) => {
            state.tabsList[name].active = name === payload.name;
        });
    },
    closeAll: (state: any): void => {
        state.tabsList = {};
    },
};
