import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";

@Options({
    name: "app-switch",
    props: {
        modelValue: Boolean,
        label: String,
    },
    emits: ["update:modelValue"],
})
export default class Switch extends Vue {
    private ID: string = uuidv4();
    private modelValue: boolean;

    public onValueChange(event: any) {
        this.$emit("update:modelValue", event.target.checked);
    }
}
