import { Options, Vue } from "vue-class-component";
import Multiselect from "@vueform/multiselect";
import API from "@/api/system/dictionary";
import { v4 as uuidv4 } from "uuid";

//https://github.com/vueform/multiselect
@Options({
    name: "app-select-dictionary",
    components: {
        "app-multi-select": Multiselect,
    },
    props: {
        modelValue: [String, Number],
        label: String,
        code: String,
        extend: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        requestCallBack: Function,
    },
    emits: ["update:modelValue", "update:changeName", "update:changeValue"],
})
export default class SelectFromDictionary extends Vue {
    private ID: string = uuidv4();
    private refSelectFromDb: any = null;
    private code: string = null;
    private extend: string = null;
    private modelValue: any = null;
    private requestCallBack: any = null;
    private list: any = [];

    public created() {
        this.refSelectFromDb = this.$refs.selectFromDb;
    }

    get value(): any {
        return this.modelValue;
    }

    set value(value: any) {
        this.$emit("update:modelValue", value);
    }

    public async selectRequest(query: any): Promise<any> {
        //let list: any = [];
        await API.dictionarySelect({
            code: this.code,
            key: query,
            extend: this.extend,
        }).then((res: any) => {
            this.list = res.data.records;
        });
        if (this.requestCallBack) this.requestCallBack(this.list);
        return this.list;
    }

    public searchChange(query: any) {
        if (this.$tools.isEmpty(query)) return;
    }

    public valueChange(query: any) {
        let name: any = "";
        for (const key in this.list) {
            if (query === this.list[key]["value"]) {
                name = this.list[key]["name"];
                break;
            }
        }
        const nameArray = name.split("-");
        name = nameArray[nameArray.length - 1];
        this.$emit("update:modelValue", query);
        this.$emit("update:changeName", name);
        this.$emit("update:changeValue", query);
    }
}
