import requests from "@/utils/axios";
import { AxiosRequestConfig } from "axios";

export default {
    modulesUrl: "",
    // 列表
    list(query: any) {
        return requests.post(this.modulesUrl + "/list", query);
    },
    // 导出Url
    exportUrl() {
        return this.modulesUrl + "/export";
    },
    // 导出
    export(query: any) {
        return requests.post(this.modulesUrl + "/export", query, <AxiosRequestConfig>{ responseType: "blob" });
    },
    // 添加
    add(query: any) {
        return requests.post(this.modulesUrl + "/add", query);
    },
    // 修改
    mod(query: any) {
        return requests.post(this.modulesUrl + "/mod", query);
    },
    // 删除
    del(query: any) {
        return requests.post(this.modulesUrl + "/del", query);
    },
    // 状态
    status(query: any) {
        return requests.post(this.modulesUrl + "/status", query);
    },
    // id选择器
    idSelect(query: any) {
        return requests.post(this.modulesUrl + "/idSelect", query);
    },
    // 获取排序
    sort(query: any) {
        return requests.post(this.modulesUrl + "/sort", query);
    },
    // 保存排序
    sortSave(query: any) {
        return requests.post(this.modulesUrl + "/sortSave", query);
    },
    // 获取上级ID
    parentId(query: any) {
        return requests.post(this.modulesUrl + "/parentId", query);
    },
};
