import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-modal",
    props: {
        title: {
            type: String,
            default: () => "未定义",
        },
        size: {
            type: String,
            default: () => "default", //sm<default<lg<xl
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        noPadding: {
            type: Boolean,
            default: () => false,
        },
        tableResponsive: {
            type: Boolean,
            default: () => false,
        },
        loseFocusClose: {
            type: Boolean,
            default: () => true,
        },
        submitFunction: [Function],
        submitAfterClose: {
            type: Boolean,
            default: () => true,
        },
        showSubmitButton: {
            type: Boolean,
            default: () => true,
        },
        showCloseButton: {
            type: Boolean,
            default: () => true,
        },
        submitButtonName: {
            type: String,
            default: () => "确定",
        },
        closeButtonName: {
            type: String,
            default: () => "关闭",
        },
    },
    emits: ["update:modelOpened", "update:modelClosed"],
})
export default class Modal extends Vue {
    private isLoading = false;

    private title: string;
    private size: string;
    private loseFocusClose: boolean;
    private submitFunction: any;
    private submitAfterClose: boolean;
    private showSubmitButton: boolean;
    private showCloseButton: boolean;
    private submitButtonName: string;
    private closeButtonName: string;

    private appElement: HTMLElement | null = null;
    private modalElement: HTMLElement | null = null;
    private modalContentElement: HTMLElement | null = null;

    private isOpen = false;
    private isFixed = true;

    public async mounted(): Promise<void> {
        this.appElement = document.getElementById("app") as HTMLElement;
    }

    public async submitModal(): Promise<void> {
        try {
            this.isLoading = true;
            await this.submitFunction()
                .then(() => {
                    if (this.submitAfterClose) this.closeModal();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        } catch (e: any) {
            //await Promise.reject(e);
        }
    }

    public autoFocus() {
        if (this.showSubmitButton && this.$refs.modalSubmitButton) {
            (this.$refs.modalSubmitButton as HTMLElement).focus();
        } else if (this.showCloseButton && this.$refs.modalCloseButton) {
            (this.$refs.modalCloseButton as HTMLElement).focus();
        }
    }

    public async openModal(fixed = true): Promise<void> {
        this.isOpen = true;
        this.isFixed = fixed;
        await this.$nextTick(() => {
            this.modalElement = this.$refs.modal as HTMLElement;
            this.modalContentElement = this.$refs.modalContent as HTMLElement;
            if (this.isFixed) this.appElement.classList.add("modal-open");
            this.appElement.classList.add("animate__fadeIn");
            this.appElement.classList.add("animate__fast");
            this.$emit("update:modelOpened");
            this.autoFocus();
        });
    }

    public closeModal(): void {
        //最后一个模态框才删除modal-open
        if (this.isFixed && !this.$tools.getParentByClass(this.modalElement, "modal")) this.appElement.classList.remove("modal-open");
        this.modalElement.classList.remove("animate__fadeIn");
        this.modalElement.classList.remove("animate__fast");
        this.modalElement.classList.add("animate__fadeOut");
        this.modalElement.classList.add("animate__faster");
        setTimeout(() => {
            this.isOpen = false;
            this.$emit("update:modelClosed");
        }, 500);
    }

    public async toggleModal(fixed = true): Promise<void> {
        if (this.isOpen) {
            this.closeModal();
        } else {
            await this.openModal(fixed);
        }
    }

    public modalBackdropClick() {
        if (!this.loseFocusClose) return;
        const isClick = this.$el.getAttribute("flag");
        if (isClick === "true") this.closeModal();
    }

    //初始位置
    private moveOffset: any = {
        x: null,
        y: null,
    };
    //鼠标按下事件
    public mouseDownHandDoc(event: any) {
        this.moveOffset.x = event.pageX - this.$el.offsetLeft;
        this.moveOffset.y = event.pageY - this.$el.offsetTop;
        event.currentTarget.style.cursor = "move";
        window.onmousemove = this.mouseMoveHandDoc;
        this.$el.setAttribute("flag", "false");
        const firstTime = new Date().getTime();
        document.onmouseup = () => {
            document.onmouseup = null;
            // onmouseup 时的时间，并计算差值
            const lastTime = new Date().getTime();
            if (lastTime - firstTime < 200) {
                this.$el.setAttribute("flag", "true");
            }
        };
    }
    //鼠标移动事件
    public mouseMoveHandDoc(event: any) {
        this.$el.style.top = event.pageY - this.moveOffset.y + "px";
        this.$el.style.left = event.pageX - this.moveOffset.x + "px";
    }
    //鼠标抬起事件
    public mouseUpHandDoc(event: any) {
        window.onmousemove = null;
        event.currentTarget.style.cursor = "move";
    }
}
