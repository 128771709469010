import store from "@/store";
import { Tools } from "@/utils/tools";
import router from "@/router";
import NProgress from "nprogress";

router.afterEach(() => {
    NProgress.done();
});

router.beforeEach(async (to) => {
    NProgress.start();
    // console.log("beforeEach ENTER", "=>", to.path);
    // console.log(to);
    //动态路由
    if (await store.dispatch("router/init", to)) {
        const asyncRoutes = store.getters["router/asyncRoutes"];
        const name = Tools.pathToName(to.path);
        if (asyncRoutes[name] && Tools.isDynamicRoute(to.path)) {
            return to.fullPath || to.path;
        }
    }
    //排除不认证的页面
    if (!Tools.isNoAuthPath(to.path)) {
        //获取页面角色
        await store.dispatch("auth/getPageRole", to.path);
        //添加tabs
        await store.dispatch("router/putTabsList", to);
    }
    //console.log(store.getters['router/tabsList']);
    //修改标题
    const siteTitle = store.getters["ui/siteTitle"];
    const pageTitle = to.meta.title ? to.meta.title : "";
    await store.dispatch("ui/setPageTitle", pageTitle);
    document.title = to.meta.title ? pageTitle + "-" + siteTitle : siteTitle;
    //验证ToKen
    if (to.meta.requiresAuth) {
        if (!store.getters["auth/token"] || !store.getters["auth/userInfo"]) {
            await store.dispatch("auth/logout");
        } else if (!store.getters["auth/userInfo"].userCode) {
            await store.dispatch("auth/logout");
        }
    }
});
