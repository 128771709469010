import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";

@Options({
    name: "app-input",
    props: {
        modelValue: [String, Number],
        label: String,
        icon: String,
        type: String,
        unit: String,
        buttonText: String,
        placeholder: String,
        autocomplete: String,
        disabled: {
            type: Boolean,
            default: () => false,
        },
        readonly: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ["update:modelValue", "update:changeValue", "buttonSubmit"],
})
export default class Input extends Vue {
    private ID: string = uuidv4();

    public onValueChange(event: any) {
        this.$emit("update:modelValue", event.target.value);
        this.$emit("update:changeValue", event.target.value);
    }

    public buttonSubmit(event: any) {
        this.$emit("buttonSubmit", event.target.value);
    }
}
