import { NAVBAR_DARK_VARIANTS, NAVBAR_LIGHT_VARIANTS, THEME_DARK_SKINS, THEME_LIGHT_SKINS } from "@/utils/themes";
import { encrypt } from "@/utils/cryptojs";
import LoginInfo from "@/entity/LoginInfo";

export default {
    setLoginInfo: (state: any, payload: LoginInfo): void => {
        state.loginInfo = payload;
        localStorage.setItem("loginInfo", encrypt(JSON.stringify(payload)));
    },
    setAutoLogin: (state: any, payload: boolean): void => {
        if (!state.loginInfo) state.loginInfo = new LoginInfo();
        state.loginInfo.autoLogin = payload;
        localStorage.setItem("loginInfo", encrypt(JSON.stringify(state.loginInfo)));
    },
    setTableRows: (state: any, payload: number): void => {
        state.tableRows = payload;
        localStorage.setItem("tableRows", String(payload));
    },
    toggleDarkMode: (state: any): void => {
        state.darkMode = !state.darkMode;
        state.darkMenu = state.darkMode;
        if (state.darkMode) {
            state.themeSkin = THEME_DARK_SKINS[0].value;
            state.navbarVariant = NAVBAR_DARK_VARIANTS[0].value;
        } else {
            state.themeSkin = THEME_LIGHT_SKINS[0].value;
            state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
        }
        localStorage.setItem("dark_mode", state.darkMode);
        localStorage.setItem("dark_menu", state.darkMenu);
        localStorage.setItem("theme_skin", state.themeSkin);
        localStorage.setItem("navbar_variant", state.navbarVariant);
    },
    toggleDarkMenu: (state: any): void => {
        state.darkMenu = !state.darkMenu;
    },
    toggleMenuSidebar: (state: any): void => {
        state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
    },
    toggleControlSidebar: (state: any): void => {
        state.controlSidebarCollapsed = !state.controlSidebarCollapsed;
    },
    toggleMenuAndHeader: (state: any): void => {
        state.menuAndHeaderCollapsed = !state.menuAndHeaderCollapsed;
    },
    setSkinReset: (state: any): void => {
        state.darkMode = false;
        state.darkMenu = true;
        state.themeSkin = THEME_LIGHT_SKINS[0].value;
        state.navbarVariant = NAVBAR_LIGHT_VARIANTS[0].value;
        localStorage.removeItem("dark_mode");
        localStorage.removeItem("dark_menu");
        localStorage.removeItem("theme_skin");
        localStorage.removeItem("navbar_variant");
    },
    setWindowSize: (state: any, payload: string): void => {
        state.screenSize = payload;
    },
    setNavbarVariant: (state: any, payload: string): void => {
        if (state.darkMode) {
            payload = "None" === payload ? "navbar-dark" : payload;
            state.navbarVariant = payload || NAVBAR_DARK_VARIANTS[0].value;
        } else {
            payload = "None" === payload ? "navbar-light" : payload;
            state.navbarVariant = payload || NAVBAR_LIGHT_VARIANTS[0].value;
        }
        localStorage.setItem("navbar_variant", state.navbarVariant);
    },
    setThemeSkin: (state: any, payload: string): void => {
        if (state.darkMode) {
            payload = "None" === payload ? "themes-dark-None" : payload;
            state.themeSkin = payload || THEME_DARK_SKINS[0].value;
        } else {
            payload = "None" === payload ? "themes-light-None" : payload;
            state.themeSkin = payload || THEME_LIGHT_SKINS[0].value;
        }
        localStorage.setItem("theme_skin", state.themeSkin);
    },
    setSiteTitle: (state: any, payload: string): void => {
        state.siteTitle = payload;
    },
    setPageTitle: (state: any, payload: string): void => {
        state.pageTitle = payload;
    },
};
