import * as moment from "moment";
import ProjectModelTableItem from "@/entity/project/ProjectModelTableItem";
export const Tools = {
    //窗体大小
    calculateWindowSize: (windowWidth: number): string => {
        if (windowWidth >= 1200) {
            return "lg";
        }
        if (windowWidth >= 992) {
            return "md";
        }
        if (windowWidth >= 768) {
            return "sm";
        }
        return "xs";
    },
    //深度拷贝
    deepCopy(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    },
    //判空
    isEmpty(v: any) {
        switch (typeof v) {
            case "undefined":
                return true;
            case "string":
                if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0) return true;
                break;
            case "boolean":
                if (!v) return true;
                break;
            case "number":
                if (0 === v || isNaN(v)) return true;
                break;
            case "object":
                if (null === v || v.length === 0) return true;
                for (const i in v) {
                    return false;
                }
                return true;
        }
        return false;
    },
    //是否是JSON
    parseJSON(str: any): any {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    },
    //是否是数字
    isNumber(number: any): boolean {
        const numReg = /^[0-9]*$/;
        return new RegExp(numReg).test(number);
    },
    //转换文件大小
    formatBytes(bytes: any, decimals = 2): string {
        if (this.isEmpty(bytes)) return "0 Bytes";
        bytes = parseInt(bytes);
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    //通过上级Class获取Dom
    getParentByClass(startTag: any, domClass: string): HTMLElement {
        // 传入标签是否是DOM对象
        if (!(startTag instanceof HTMLElement)) return;
        // 父级标签是否是body,是着停止返回集合,反之继续
        let nodeName = "";
        if (startTag.parentElement) {
            nodeName = startTag.parentElement.nodeName ? startTag.parentElement.nodeName : "";
        } else {
            return;
        }
        if ("BODY" !== nodeName) {
            if (startTag.parentElement.classList.contains(domClass)) {
                return startTag.parentElement;
            } else {
                if (startTag.parentElement.parentElement) {
                    return this.getParentByClass(startTag.parentElement, domClass);
                } else {
                    return null;
                }
            }
        }
    },
    //是否不需要认证
    isNoAuthPath: (path: string): boolean => {
        if (path === "/login") return true;
        if (path === "/loginDingtalk") return true;
        if (path === "/loginModPass") return true;
        return path.length < 1;
    },
    //是否是动态路由
    isDynamicRoute: (path: string): boolean => {
        if (path === "/") return false;
        if (path === "/profile") return false;
        if (path === "/login") return false;
        if (path === "/loginDingtalk") return false;
        if (path === "/loginModPass") return false;
        if (path === "/error/403") return false;
        if (path === "/error/404") return false;
        return path.length > 0;
    },
    //path转换为name
    pathToName: (str: string): string => {
        const path: string = "/" === str.substr(0, 1) ? str.substr(1) : str;
        return path.replace(/\//g, "-");
    },
    //自动补0
    prefixInteger(num: number, length: number) {
        return (Array(length).join("0") + num).slice(-length);
    },
    //分钟转换
    minuteToString(minute: number): string {
        if (this.isEmpty(minute) || minute < 1) return "1分";
        const day = parseInt(String(minute / 60 / 24));
        const hour = parseInt(String((minute / 60) % 24));
        const min = parseInt(String(minute % 60));
        let statusMinute = "";
        if (day > 0) {
            statusMinute = day + "天";
        }
        if (hour > 0) {
            statusMinute += hour + "时";
        }
        if (day < 1 && min > 0) {
            statusMinute += parseFloat(String(min)) + "分";
        }
        return statusMinute;
    },
    //秒转换
    secondToString(second: number): string {
        if (this.isEmpty(second) || second < 1) return "1分";
        const day = parseInt(String(second / 60 / 60 / 24));
        const hour = parseInt(String((second / 60 / 60) % 24));
        const min = parseInt(String((second / 60) % 60));
        const sec = parseInt(String(second % 60));
        let statusSecond = "";
        if (day > 0) {
            statusSecond = day + "天";
        }
        if (hour > 0) {
            statusSecond += hour + "时";
        }
        if (day < 1 && min > 0) {
            statusSecond += parseFloat(String(min)) + "分";
        }
        if (day < 1 && hour < 1 && sec > 0) {
            statusSecond += parseFloat(String(sec)) + "秒";
        }
        return statusSecond;
    },
    //格式化日期
    formatDate(time: any): any {
        return (moment as any)(time).format("YYYY-MM-DD");
    },
    //格式化简写时间
    formatMiniTime(time: any): any {
        return (moment as any)(time).format("HH:mm");
    },
    //格式化时间
    formatTime(time: any): any {
        return (moment as any)(time).format("YYYY-MM-DD HH:mm:ss");
    },
    //格式化时间
    formatTime1(time: any): any {
        return (moment as any)(time).format("MM-DD HH:mm");
    },
    //格式化简单日期
    formatSimpleDate(datetime: any): any {
        if (this.isEmpty(datetime)) return "";
        const tmp = datetime.split(" ")[0].split("-");
        return tmp[1] + "-" + tmp[2];
    },
    //格式化简单时间
    formatSimpleTime(datetime: any): any {
        if (this.isEmpty(datetime)) return "";
        return datetime.split(" ")[1];
    },
    //格式化简单时间
    formatSimpleTime1(datetime: any): any {
        if (this.isEmpty(datetime)) return "";
        const tmp = datetime.split(" ")[1];
        const tmpArray = tmp.split(":");
        return tmpArray[0] + ":" + tmpArray[1];
    },
    //格式化简单日期时间
    formatSimpleTime2(datetime: any): any {
        if (this.isEmpty(datetime)) return "";
        const tmp1 = datetime.split(" ")[0];
        const tmp1Array = tmp1.split("-");
        const tmp2 = datetime.split(" ")[1];
        const tmp2Array = tmp2.split(":");
        return tmp1Array[1] + "-" + tmp1Array[2] + " " + tmp2Array[0] + ":" + tmp2Array[1];
    },
    //格式化流程业务状态
    formatBusinessStatus(id: any): any {
        const map: any = {
            "1": "正在处理",
            "2": "已撤销",
            "3": "已驳回",
            "4": "已办结",
        };
        id = id + "";
        if (id in map) {
            return map[id];
        } else {
            return "未知";
        }
    },
    //扁平化form
    flatFormItem(formItemTree: any): any {
        const result: any = [];
        const candidateFormItems: any = [];
        formItemTree.forEach((it: any) => candidateFormItems.push(it));
        while (candidateFormItems.length > 0) {
            const formItem = candidateFormItems.pop();
            if (formItem.name === "SpanLayout") {
                formItem.props.items.forEach((it: any) => candidateFormItems.push(it));
            } else {
                result.push(formItem);
            }
        }
        return result;
    },
    //生成随机字符串
    generateRandomString(num: number): string {
        const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
        const arr: string[] = [];

        for (let i = 0; i < num; i++) {
            const randomIndex = Math.floor(Math.random() * a.length);
            arr.push(a[randomIndex]);
            //a.splice(randomIndex, 1);
        }

        return arr.join("");
    },
    //向上查找指定DOM节点
    findPreDomNode(el: HTMLElement, nodeName: string): HTMLElement {
        while (el && el.parentElement) {
            el = el.parentElement;
            if (el.nodeName.toLowerCase() === nodeName.toLowerCase()) {
                return el;
            }
        }
        return null;
    },
    //向上查找指定对象节点
    getPreModelNode(obj: ProjectModelTableItem[], node: ProjectModelTableItem): ProjectModelTableItem {
        const modelParentId = node.modelParentId;
        for (let i = 0; i < obj.length; i++) {
            const item = obj[i];
            if (item.modelId === modelParentId) return item;
            if (item.children) {
                const newNode = Tools.getPreModelNode(item.children, node);
                if (newNode) return newNode;
            }
        }
        return null;
    },
};

export default {
    install: (app: any) => {
        app.config.globalProperties.$tools = Tools;
    },
};
