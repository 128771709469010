import router, { routes } from "@/router";
import AuthApi from "@/api/auth";
import { Tools } from "@/utils/tools";

export default {
    setMenu: (context: any, payload: any): void => {
        context.commit("setMenu", payload);
    },
    setAllRoutes: (context: any, payload: any): void => {
        context.commit("setAllRoutes", payload);
    },
    setAsyncRoutes: (context: any, payload: any): void => {
        context.commit("setAsyncRoutes", payload);
    },
    putTabsList: (context: any, payload: any): void => {
        context.commit("putTabsList", payload);
    },
    closeAll: (context: any): void => {
        context.commit("closeAll");
    },
    init: async (context: any, payload: any): Promise<boolean> => {
        if (context.state.menu.length < 1 && !Tools.isNoAuthPath(payload.path)) {
            //获取菜单
            await AuthApi.getPageMenu().then((res: any) => {
                //设置菜单
                context.commit("setMenu", res.data);
                //动态路由
                generateRoutes(res.data);
                context.commit("setAsyncRoutes", asyncRoutes);
                //合并路由
                for (const key in asyncRoutes) {
                    routes[0].children.push((asyncRoutes as any)[key]);
                }
                context.commit("setAllRoutes", routes);
            });
            return true;
        } else {
            return false;
        }
    },
};

//生成动态路由
export const asyncRoutes: any = {};
export const generateRoutes = function (menus: any): void {
    menus.forEach((item: any) => {
        if (item.children) {
            generateRoutes(item.children);
        } else {
            if (item.path) {
                if (!Tools.isDynamicRoute(item.path)) return;
                const path = item.path;
                const name = Tools.pathToName(item.path);
                // console.log(name, path);
                // eslint-disable-next-line no-prototype-builtins
                if (!asyncRoutes.hasOwnProperty(name)) {
                    let component;
                    if (item.component) {
                        component = () => import(/* webpackChunkName: "[request]" */ `@/pages/${item.component}.vue`);
                    } else {
                        component = () => import(/* webpackChunkName: "blank" */ `@/pages/blank/blank.vue`);
                    }
                    asyncRoutes[name] = {
                        name,
                        component,
                        path,
                        meta: {},
                    };
                    if (item.title) asyncRoutes[name].meta.title = item.title;
                    if (item.icon) {
                        asyncRoutes[name].meta.icon = item.icon;
                    } else {
                        if (item.children) {
                            asyncRoutes[name].meta.icon = "ellipse";
                        } else {
                            asyncRoutes[name].meta.icon = "ellipse-outline";
                        }
                    }
                    if (item.requiresAuth) asyncRoutes[name].meta.requiresAuth = item.requiresAuth;
                    if (item.requiresUnauth) asyncRoutes[name].meta.requiresUnauth = item.requiresUnauth;
                    if (item.keepAlive) asyncRoutes[name].meta.keepAlive = item.keepAlive;
                    if (!router.hasRoute(name)) router.addRoute("main", asyncRoutes[name]);
                }
            }
        }
    });
};
