import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const flowableModule: any = {
    namespaced: true,
    state: {
        nodeMap: new Map(),
        isEdit: null,
        selectedNode: {},
        selectFormItem: null,
        design: {},
        runningList: [],
        noTakeList: [],
        endList: [],
        diagramMode: "design",
        diagramQuery: {},
        detailVOList: {},
        startUser: {},
        formData: {},
        currentNode: {},
        processInfo: {},
    },
    mutations,
    actions,
    getters,
};
export default flowableModule;
