require("@/utils/CustomUtil");
import { createApp } from "vue";
import App from "@/app/app.vue";
import router from "@/router";
import store from "@/store";
import tools from "@/utils/tools";
import "@/router/permission";

import ContentHeader from "@/modules/content-header/content-header.vue";
import SvgIcon from "@/components/svg-icon/svg-icon.vue";

import Input from "@/components/input/input.vue";
import InputAuto from "@/components/input-auto/input-auto.vue";
import Textarea from "@/components/textarea/textarea.vue";
import Button from "@/components/button/button.vue";
import ButtonTooltip from "@/components/button-tooltip/button-tooltip.vue";
import Checkbox from "@/components/checkbox/checkbox.vue";
import Switch from "@/components/switch/switch.vue";
import Select from "@/components/select/select.vue";
import SelectStar from "@/modules/select-star/select-star.vue";
import SelectFromDictionary from "@/modules/select-from-dictionary/select-from-dictionary.vue";
import SelectFromDb from "@/modules/select-from-db/select-from-db.vue";
import SwitchNumber from "@/components/switch-number/switch-number.vue";
import Alert from "@/components/alert/alert.vue";
import Modal from "@/components/modal/modal.vue";
import Star from "@/components/star/star.vue";
import Ellipsis from "@/components/ellipsis/ellipsis.vue";
import Dialog from "@/components/dialog/dialog.vue";

import ModelInput from "@/modules/model-input/model-input.vue";
import ModelTextarea from "@/modules/model-textarea/model-textarea.vue";
import ModelSelectFromDb from "@/modules/model-select-from-db/model-select-from-db.vue";

import Toast, { PluginOptions } from "vue-toastification";
import { VueWindowSizePlugin } from "vue-window-size/option-api";

import { InstallCodemirro } from "codemirror-editor-vue3";

import ElementPlus from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "./icons";
import "./main.scss";

import { VueDraggableNext } from "vue-draggable-next";
import VueAlert from "@/components/vue-alert/index";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const ToastOptions: PluginOptions = {
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
};

const app = createApp(App)
    .component("app-content-header", ContentHeader)
    .component("app-input", Input)
    .component("app-input-auto", InputAuto)
    .component("app-textarea", Textarea)
    .component("app-button", Button)
    .component("app-button-tooltip", ButtonTooltip)
    .component("app-checkbox", Checkbox)
    .component("app-switch", Switch)
    .component("app-select", Select)
    .component("app-select-star", SelectStar)
    .component("app-select-from-db", SelectFromDb)
    .component("app-select-from-dictionary", SelectFromDictionary)
    .component("app-switch-number", SwitchNumber)
    .component("app-alert", Alert)
    .component("app-modal", Modal)
    .component("app-star", Star)
    .component("app-ellipsis", Ellipsis)
    .component("app-model-input", ModelInput)
    .component("app-model-textarea", ModelTextarea)
    .component("app-model-select-from-db", ModelSelectFromDb)
    .component("svg-icon", SvgIcon)
    .component("vue-draggable-next", VueDraggableNext)
    .use(InstallCodemirro)
    .use(VueAlert)
    .use(tools)
    .use(store)
    .use(router)
    .use(VueWindowSizePlugin)
    .use(Toast, ToastOptions)
    .use(ElementPlus, { size: "default", zIndex: 3000, locale: zhCn })
    //ElementPlus相关组件
    .component("app-dialog", Dialog);
//注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.mount("#app");
