import LoginInfo from "@/entity/LoginInfo";

export default {
    setLoginInfo: (context: any, payload: LoginInfo): void => {
        context.commit("setLoginInfo", payload);
    },
    setAutoLogin: (context: any, payload: boolean): void => {
        context.commit("setAutoLogin", payload);
    },
    setTableRows: (context: any, payload: number): void => {
        context.commit("setTableRows", payload);
    },
    toggleDarkMode: (context: any): any => {
        context.commit("toggleDarkMode");
    },
    toggleDarkMenu: (context: any): any => {
        context.commit("toggleDarkMenu");
    },
    toggleMenuSidebar: (context: any): any => {
        context.commit("toggleMenuSidebar");
    },
    toggleControlSidebar: (context: any): any => {
        context.commit("toggleControlSidebar");
    },
    toggleMenuAndHeader: (context: any): any => {
        context.commit("toggleMenuAndHeader");
    },
    setSkinReset: (context: any): any => {
        context.commit("setSkinReset");
    },
    setWindowSize: (context: any, payload: string): void => {
        context.commit("setWindowSize", payload);
    },
    setThemeSkin: (context: any, payload: string): void => {
        context.commit("setThemeSkin", payload);
    },
    setNavbarVariant: (context: any, payload: string): void => {
        context.commit("setNavbarVariant", payload);
    },
    setSidebarSkin: (context: any, payload: string): void => {
        context.commit("setSidebarSkin", payload);
    },
    setSiteTitle: (context: any, payload: string): void => {
        context.commit("setSiteTitle", payload);
    },
    setPageTitle: (context: any, payload: string): void => {
        context.commit("setPageTitle", payload);
    },
};
