export default {
    nodeMap: (state: any): any => state.nodeMap,
    isEdit: (state: any): any => state.isEdit,
    selectedNode: (state: any): any => state.selectedNode,
    selectFormItem: (state: any): any => state.selectFormItem,
    design: (state: any): any => state.design,
    runningList: (state: any): any => state.runningList,
    noTakeList: (state: any): any => state.noTakeList,
    endList: (state: any): any => state.endList,
    diagramMode: (state: any): any => state.diagramMode,
    diagramQuery: (state: any): any => state.diagramQuery,
    detailVOList: (state: any): any => state.detailVOList,
    startUser: (state: any): any => state.startUser,
    formData: (state: any): any => state.formData,
    currentNode: (state: any): any => state.currentNode,
    processInfo: (state: any): any => state.processInfo,
};
