import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import { Tools } from "@/utils/tools";
import { NAVBAR_LIGHT_VARIANTS, THEME_LIGHT_SKINS } from "@/utils/themes";
import { decrypt } from "@/utils/cryptojs";
import LoginInfo from "@/entity/LoginInfo";

const uiModule: any = {
    namespaced: true,
    state: {
        loginInfo: getLoginInfo(),
        tableRows: Number(localStorage.getItem("tableRows")) || 8,
        darkMode: localStorage.getItem("dark_mode") === "true" || false,
        darkMenu: localStorage.getItem("dark_menu") === "true" || true,
        themeSkin: localStorage.getItem("theme_skin") || THEME_LIGHT_SKINS[0].value,
        navbarVariant: localStorage.getItem("navbar_variant") || NAVBAR_LIGHT_VARIANTS[0].value,
        menuSidebarCollapsed: false,
        controlSidebarCollapsed: true,
        menuAndHeaderCollapsed: false,
        screenSize: Tools.calculateWindowSize(window.innerWidth),
        siteTitle: "速泰运维平台",
        pageTitle: "",
    },
    mutations,
    actions,
    getters,
};

function getLoginInfo(): LoginInfo {
    try {
        const loginInfo: any = localStorage.getItem("loginInfo");
        if (loginInfo) {
            return JSON.parse(decrypt(loginInfo));
        } else {
            return new LoginInfo();
        }
    } catch (e: any) {
        return new LoginInfo();
    }
}

export default uiModule;
