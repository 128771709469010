export default {
    setNodeMap: (context: any, payload: any): void => {
        context.commit("setNodeMap", payload);
    },
    setEdit: (context: any, payload: any): void => {
        context.commit("setEdit", payload);
    },
    setSelectedNode: (context: any, payload: any): void => {
        context.commit("setSelectedNode", payload);
    },
    setSelectFormItem: (context: any, payload: any): void => {
        context.commit("setSelectFormItem", payload);
    },
    setDesign: (context: any, payload: any): void => {
        context.commit("setDesign", payload);
    },
    setRunningList: (context: any, payload: any): void => {
        context.commit("setRunningList", payload);
    },
    setNoTakeList: (context: any, payload: any): void => {
        context.commit("setNoTakeList", payload);
    },
    setEndList: (context: any, payload: any): void => {
        context.commit("setEndList", payload);
    },
    setDiagramMode: (context: any, payload: any): void => {
        context.commit("setDiagramMode", payload);
    },
    setDiagramQuery: (context: any, payload: any): void => {
        context.commit("setDiagramQuery", payload);
    },
    setDetailVOList: (context: any, payload: any): void => {
        context.commit("setDetailVOList", payload);
    },
    setStartUser: (context: any, payload: any): void => {
        context.commit("setStartUser", payload);
    },
    setFormData: (context: any, payload: any): void => {
        context.commit("setFormData", payload);
    },
    setCurrentNode: (context: any, payload: any): void => {
        context.commit("setCurrentNode", payload);
    },
    setProcessInfo: (context: any, payload: any): void => {
        context.commit("setProcessInfo", payload);
    },
};
