import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

const routerModule: any = {
    namespaced: true,
    state: {
        menu: [],
        allRoutes: [],
        asyncRoutes: [],
        tabsList: {},
    },
    mutations,
    actions,
    getters,
};

export default routerModule;
